import React from "react";


export default function Pedidos({ pedido, index, handlePaginaDetalhesPedido, calcularProgresso }) {

    const { width } = calcularProgresso(pedido.pedidoStatusCodigo,pedido.dataLimiteEntrega, pedido.dataAprovacao, pedido.datalimitemanuseio);

    return (
        <div className='conteudo-dashboard'>
            <div className='pedido' key={index} onClick={() => handlePaginaDetalhesPedido(pedido.pedidoCodigo)}>
                <h5>Pedido: {pedido.numeroPedido}</h5>
                <h6>DESTINATÁRIO: {pedido.nomeDestinatario}</h6>
                <p>{pedido.cidade} - {pedido.ufSigla} - CEP {pedido.cepFormatado}</p>
                <p>Prazo de Entrega: {pedido.dataLimiteEntrega}</p>
                <h5>{pedido.statusPedido} : {pedido.dataUltimaAlteracao}</h5>
                <div className='progress-container'>
                    <div className="progress-bar" style={{ width: width }}>
                    </div>
                </div>
            </div>
        </div>
    )
}