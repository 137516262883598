import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './tabelaUltimos60Dias.css';
import Header from '../../componentes/Header';
import api from '../../services/api';
import { XmlToJsonConvert, descriptografarCodigo, formatarData, calcularProgresso, criptografarCodigo } from '../../utils/utils';
import Pagination from '../../componentes/Pagination';
import Pesquisar from '../../componentes/Pesquisar';
import Pedidos from '../../componentes/Pedidos';
import Loading from '../../componentes/Loading';

export default function TabelaUltimos60Dias() {
    const tituloHeader = 'Alterações Ultimos 60 dias'
    const [dados, setDados] = useState([])
    const [skip, setSkip] = useState(0);
    const [filtro, setFiltro] = useState('')
    const usuarioCodigo = descriptografarCodigo(window.localStorage.getItem('usuarioCodigo'))
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();


    const handleFiltro = (event) => {
        setFiltro(event.target.value)
    }
    function handlePaginaDetalhesPedido(codigoPedido) {
        const pedidoCodigo = criptografarCodigo(codigoPedido.toString())
        window.localStorage.setItem('tokenPedido', pedidoCodigo);
        navigate(`/DetalhesPedidosAlteradosGNos/`);
    }

    const handleBuscarDadosUltimos60Dias = async () => {
        try {
            setLoading(true)
            const response = await api.get(`WsRotinas.asmx/ObterUltimasAlteracoes`, { params: { usuarioCodigo: usuarioCodigo } })
            const json = XmlToJsonConvert(response.data)

            const dadosFormatado = json.map(e => ({
                ...e,
                dataPedido: formatarData(e.dataPedido),
                dataUltimaAlteracao: formatarData(e.dataUltimaAlteracao),
                dataLimiteEntrega: formatarData(e.dataLimiteEntrega)
            }));
            setDados(dadosFormatado);
            setLoading(false)
        } catch (error) {
            setLoading(false)
            navigate(`/`)
        }
    }

    useEffect(() => {
        handleBuscarDadosUltimos60Dias();
    }, [])

    const dadosFiltrados = dados.filter(pedido => {
        const pesquisar = `${pedido.numeroPedido} ${pedido.nomeDestinatario} ${pedido.cidade} ${pedido.ufSigla} ${pedido.dataLimiteEntrega} ${pedido.statusPedido} ${pedido.dataUltimaAlteracao} ${pedido.cdCpfCnpj}`;
        return pesquisar.toLowerCase().includes(filtro.toLowerCase());
    });

    const paginatedData = dadosFiltrados.slice(skip, skip + 50);

    return (
        <div>
            <Header titulo={tituloHeader} />
            <div className='div-conteudo'>
                <Pesquisar className="pesquisarTabela" filtro={filtro} handleFiltro={handleFiltro} />
            {loading ? <Loading/> :
                paginatedData.length === 0 ? <h3>Nenhum pedido encontrado</h3> : 
                    paginatedData.map((e, index) => {
                        return (
                            <Pedidos 
                                key={index}
                                pedido={e}
                                index={index}
                                handlePaginaDetalhesPedido={handlePaginaDetalhesPedido}
                                calcularProgresso={calcularProgresso}
                            />
                        )
                    })
                }
                <Pagination
                    limit={50}
                    total={dados.length}
                    skip={skip}
                    setSkip={setSkip}
                />
            </div>
        </div>
    );
}
