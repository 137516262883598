import { Routes, Route } from 'react-router-dom'
import PedidosAlteradosGNos from '../paginas/PedidosAlteradosGNos'
import DetalhesPedidosAlteradosGNos from '../paginas/DetalhesPedidosAlteradosGNos'
import TabelaUltimos60Dias from '../paginas/TabelaUltimos60Dias'
import Erro from '../componentes/Erro'

export default function RoutesApp() {
    return (
        <div id="root">
            <Routes>
                <Route path='/PedidosAlteradosPorCL/:id' element={<PedidosAlteradosGNos />} />
                <Route path='/DetalhesPedidosAlteradosGNos/' element={<DetalhesPedidosAlteradosGNos />} />
                <Route path='/AlteracoesUltimosDias/' element={<TabelaUltimos60Dias />} />
                <Route path='/' element={<Erro />} />
            </Routes>
        </div>
    )
}