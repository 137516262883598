import CryptoJS from 'crypto-js';

export const secretKey = 'abc';

export function criptografarCodigo(codigo){
    return CryptoJS.AES.encrypt(codigo, secretKey).toString();
  };

export function descriptografarCodigo(codigoCriptografado) {
    const bytes = CryptoJS.AES.decrypt(codigoCriptografado, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
}

export function XmlToJsonConvert(request) {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(request, 'text/xml');
    const string = xmlDoc.querySelector('string').textContent;
    return JSON.parse(string);
}

export function formatarData(data) {
    return data ? new Date(parseInt(data.substr(6))).toLocaleDateString() : '';
}

export function formatarDinheiro(valor) {
    valor = parseFloat(valor).toFixed(2);
    let valorFormatado = valor.toString().replace('.', ',');
    valorFormatado = valorFormatado.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    valorFormatado = `R$ ${valorFormatado}`;

    return valorFormatado;
}
export function XmlToIntConvert(request) {
    const regex = /<int[^>]*>(\d+)<\/int>/;
    const match = request.match(regex);
    return match ? parseInt(match[1]) : null;
}

export function formatarCep(cep) {
    return cep.replace(/(\d{5})(\d{3})/, '$1-$2');
}

export function calcularProgresso(pedidoStatusCodigo, dataLimiteEntrega, dataAprovacao, datalimitemanuseio) {
    let width = '100%';
    const minValue = new Date('0001-01-01T00:00:00Z');

    const dataPedidoEntrega = new Date(dataLimiteEntrega);
    const dataPedidoManuseado = new Date(datalimitemanuseio);
    const dataPedidoAprovado = new Date(dataAprovacao);

    if (!isNaN(dataPedidoEntrega) && dataPedidoEntrega.getTime() !== minValue.getTime()) {
        width = '100%';
    } else if (!isNaN(dataPedidoManuseado) && dataPedidoManuseado.getTime() !== minValue.getTime()) {
        width = '60%';
    } else if (!isNaN(dataPedidoAprovado) && dataPedidoAprovado.getTime() !== minValue.getTime()) {
        width = '40%';
    } else if ([1, 25, 50].includes(pedidoStatusCodigo)) {
        width = '20%';
    } else {
        width = '0';
    } return { width };
}