import React from "react"
import './pesquisar.css'
export default function Pesquisar({ filtro, handleFiltro }) {
    {/*A variável 'filtro' contém o que o usuário digita. Para realizar o filtro, você pode pegar o JSON retornado pela API/WebService e aplicar um método .filter() no array mantido pelo useState([]), que contém todos os objetos. Em seguida, use o método includes() para verificar se a string de pesquisa fornecida pelo usuário está contida nos campos relevantes do objeto. Por exemplo:

    - Defina o estado do filtro e dos dados da API:

    const [filtro, setFiltro] = useState('');
    const [dadosApi, setDadosApi] = useState([]);

    - Use o método filter() para filtrar os dados da API com base no que o usuário digita:

    const dadosFiltrados = dadosApi.filter(item => {
    // Concatene os campos relevantes do objeto para criar uma string de pesquisa
    const pesquisar = `${item.OSeuObjeto} ...`;

    // Verifique se a string de pesquisa inclui o texto fornecido pelo usuário (ignorando maiúsculas e minúsculas)
    return pesquisar.toLowerCase().includes(filtro.toLowerCase());
    });
    - O array 'dadosFiltrados' conterá os objetos filtrados conforme especificado pelo usuário.

    */}
    return (
        <input className="form-control pesquisar" type='text' placeholder="Pesquise aqui..." value={filtro} onChange={handleFiltro} />
    )
}