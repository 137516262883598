import {useState, useEffect } from 'react';
import './pagination.css'

const Pagination = ({
    limit,
    total,
    skip,
    setSkip
}) => {
    const [MAX_ITEMS, setSlidesPerView] = useState(5);

    const MAX_LEFT = (MAX_ITEMS - 1) / 2;

    const current = skip ? skip / limit + 1 : 1;
    const pages = Math.ceil(total / limit);
    const maxFirst = Math.max(pages - (MAX_ITEMS - 1), 1);
    const first = Math.min(
        Math.max(current - MAX_LEFT, 1),
        maxFirst
    );

    function onPageChange(page) {
        setSkip((page - 1) * limit);
    }
    useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 380) {
                setSlidesPerView(3)
            }
            else if (window.innerWidth >= 932){
                setSlidesPerView(9)
            }
            else{
                setSlidesPerView(5)
            }
        }
        handleResize()
        window.addEventListener("resize", handleResize)
        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])
    return (
        <ul className="pagination">
            <li>
                <button style={ { backgroundColor: 'var(--color-azul-sistema)'}} className='btn btn-primary' onClick={() => onPageChange(current - 1)} disabled={current === 1}>Anterior</button>
            </li>
            {Array.from({ length: Math.min(MAX_ITEMS, pages) })
                .map((_, index) => index + first)
                .map((page) => (
                    <li key={page}>
                        <button  onClick={() => onPageChange(page)} style={{ backgroundColor: 'var(--color-azul-sistema)', color: 'white', borderRadius: '3px', border: 'none' }} className={page === current ? 'pagination__item--active' : null}>{page}</button>
                    </li>
                ))}
            <li>
                <button style={ { backgroundColor: 'var(--color-azul-sistema)'}} className='btn btn-primary'  onClick={() => onPageChange(current + 1)} disabled={current === pages}>Próxima</button>
            </li>
        </ul>
    );
};

export default Pagination;
