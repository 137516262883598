import Header from '../../componentes/Header';
import { useState, useEffect } from "react"
import api from '../../services/api'
import Pagination from '../../componentes/Pagination';
import './pedidosAlteradosGNos.css'
import { useParams, useNavigate } from 'react-router-dom';
import Pedidos from '../../componentes/Pedidos'
import Pesquisar from '../../componentes/Pesquisar'
import Loading from '../../componentes/Loading';
import { XmlToJsonConvert, XmlToIntConvert, formatarData, formatarCep, criptografarCodigo, calcularProgresso } from '../../utils/utils';

export default function PedidosAlteradosGNos() {
    const [dados, setDados] = useState([]);
    const [skip, setSkip] = useState(0);
    const [filtro, setFiltro] = useState('');
    const [loading, setLoading] = useState(false);
    const [label, setLabel] = useState('')
    const { id } = useParams();
    window.localStorage.setItem('tokenNotificacaoUsuario', id)
    const navigate = useNavigate();
    const tituloHeader = 'Meus Pedidos'


    const handleFiltro = (event) => {
        setFiltro(event.target.value);
    }

    function handlePaginaDetalhesPedido(codigoPedido) {
        const pedidoCodigo = criptografarCodigo(codigoPedido.toString())
        window.localStorage.setItem('tokenPedido', pedidoCodigo);
        navigate(`/DetalhesPedidosAlteradosGNos/`);
    }

    useEffect(() => {
        var codigo = ""
        const handleObterPedidosUsuarioPorDestinatarioNotificacao = async () => {         
            try {
                setLoading(true);
                const responseParam = await api.get('WsRotinas.asmx/descriptografarID', { params: { token: id } });
                codigo = XmlToIntConvert(responseParam.data)

                if (codigo) {
                    const response = await api.get('WsRotinas.asmx/ObterDados', { params: { notificacaoCodigo: codigo } });
                    const json = XmlToJsonConvert(response.data);

                    const dadosFormatados = json.map(e => ({
                        ...e,
                        dataLimiteEntrega: formatarData(e.dataLimiteEntrega),
                        dataUltimaAlteracao: formatarData(e.dataUltimaAlteracao),
                        cepFormatado: formatarCep(e.cep),
                    }));
                    window.localStorage.setItem('usuarioCodigo', criptografarCodigo(dadosFormatados[0].usuarioCodigo.toString()))

                    setDados(dadosFormatados);
                } else {
                    navigate(`/`)
                }
                setLoading(false)
            } catch (error) {
                setLoading(false)
                navigate(`/`)
                console.error('Erro ao obter dados:', error);
            }
        };
        const handleObterAlertaPagina = async () => {
            try {
                if (id) {
                    const response = await api.get(`WsRotinas.asmx/ObterAlertaPrimeiraPagina`, { params: { codigoCriptografado: id } })
                    const json = XmlToJsonConvert(response.data);
                    setLabel(json);
                }
            }
            catch (error) {
        }
    }
        handleObterPedidosUsuarioPorDestinatarioNotificacao();
    handleObterAlertaPagina();
}, [id]);

const dadosFiltrados = dados.filter(pedido => {
    const pesquisar = `${pedido.numeroPedido} ${pedido.nomeDestinatario} ${pedido.cidade} ${pedido.ufSigla} ${pedido.cepFormatado} ${pedido.dataLimiteEntrega} ${pedido.statusPedido} ${pedido.dataUltimaAlteracao}`;
    return pesquisar.toLowerCase().includes(filtro.toLowerCase());
});

const paginatedData = dadosFiltrados.slice(skip, skip + 50);

return (
    <div>
        <Header titulo={tituloHeader} />
        <div className='div-conteudo'>
        <label style={{ textAlign: 'center', marginBottom: '10px'}}>{label}</label>
            <Pesquisar filtro={filtro} handleFiltro={handleFiltro} />
            {loading == true ? <Loading /> :
                paginatedData.length === 0 ? <h3>Nenhum pedido encontrado</h3> :
                    paginatedData.map((e, index) => {
                        return (
                            <Pedidos
                                key={index}
                                pedido={e}
                                index={index}
                                handlePaginaDetalhesPedido={handlePaginaDetalhesPedido}
                                calcularProgresso={calcularProgresso}
                            />
                        )
                    })
            }
            <Pagination
                limit={50}
                total={dados.length}
                skip={skip}
                setSkip={setSkip}
            />
        </div>
    </div>
);
}
