import React from "react"

export default function Loading() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15%' }}>
            <div style={{ color: 'var(--color-azul-sistema)', width: '3rem', height: '3rem' }} class="spinner-border-sm spinner-border" role="status">
            </div>
        </div>
    )
}
