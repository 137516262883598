import React from "react";
import './header.css'
import { IoArrowBack } from "react-icons/io5";
import { Link } from "react-router-dom";
import { VscGitPullRequestNewChanges } from "react-icons/vsc";
export default function Header({ titulo}) {
    const tokenNotificacao = window.localStorage.getItem("tokenNotificacaoUsuario");

    function handleClick (){
        localStorage.removeItem('tokenPedido');
    }
    return (
            <div className="div-header">
                <nav className="nav-container">
                  {titulo == "Detalhes Pedido" ? <Link to={`/PedidosAlteradosPorCL/${tokenNotificacao}`} onClick={handleClick}><IoArrowBack style={{width: '100px', color: 'white'}} className="icons" /></Link> : "" }  
                  {titulo == "Meus Pedidos" ? <Link to={`/AlteracoesUltimosDias/`}><VscGitPullRequestNewChanges style={{width: '100px', color: 'white'}} className="icons" /></Link> : ""}
                  {titulo == "Alterações Ultimos 60 dias" ? <Link to={`/PedidosAlteradosPorCL/${tokenNotificacao}`} onClick={handleClick}><IoArrowBack style={{width: '100px', color: 'white'}} className="icons" /></Link> : "" }
                    <h1>{titulo}</h1>
                </nav>
            </div>
    )
}