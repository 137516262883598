import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../../componentes/Header'
import { FaRegFolderClosed } from "react-icons/fa6";
import { FaClipboardList } from "react-icons/fa";
import { FaSpinner } from "react-icons/fa";
import './detalhesPedidosAlteradosGNos.css'
import CryptoJS from 'crypto-js';
import api from '../../services/api';
import Loading from '../../componentes/Loading';
import { IoIosCheckmarkCircle } from "react-icons/io";
import { XmlToJsonConvert, formatarDinheiro, formatarData, descriptografarCodigo } from '../../utils/utils';

export default function DetalhesPedidosAlteradosGNos() {
    const pedidoCodigo = descriptografarCodigo(window.localStorage.getItem('tokenPedido'))
    const [numeroPedido, setNumeroPedido] = useState('')
    const [nfe, setNfe] = useState('')
    const [nomeDestinatario, setNomeDestinatario] = useState('')
    const [cidade, setCidade] = useState('')
    const [numero, setNumero] = useState('')
    const [uf, setUf] = useState('')
    const [tipoModal, setTipoModal] = useState('')
    const [peso, setPeso] = useState('')
    const [volume, setVolumes] = useState('')
    const [prazoEntrega, setPrazoEntrega] = useState('')
    const [valorFrete, setValorFrete] = useState('')
    const [historico, setHistorico] = useState([])
    const [produtos, setProdutos] = useState([])
    const [nfeEmitidaCodigo, setNfeEmitidaCodigo] = useState('')
    const [caminhoArquivoBase, setCaminhoArquivoBase] = useState('')
    const [loading, setLoading] = useState(false)
    const tituloHeader = 'Detalhes Pedido'
    const navigate = useNavigate();

    function handleBaixarPdf() {
        if (nfeEmitidaCodigo || caminhoArquivoBase) {
            handleBaixaPdf();
        }

    }
    const handleBaixaPdf = async () => {
        setLoading(true)
        var urlPdf = "";
        if (nfeEmitidaCodigo) {
            const response = await api.get(`WsRotinas.asmx/BaixarPDFNotaNfeEmitidaCodigo`, { params: { nfeEmitidaCodigo: nfeEmitidaCodigo } })
            urlPdf = XmlToJsonConvert(response.data)
            
        } else if (caminhoArquivoBase) {
            const response = await api.get(`WsRotinas.asmx/BaixarPDFNotaCaminhoArquivoBase`, { param: { caminhoArquivoBase: caminhoArquivoBase } })
            urlPdf = XmlToJsonConvert(response.data)
        }   
        setLoading(false)
        window.location.href = urlPdf
    }

    const handleObterDetalhePedido = async () => {
        try {
            await api.get(`WsRotinas.asmx/ObterDetalhesDoPedido`, { params: { pedidoCodigo: pedidoCodigo } }).then((response) => {
                const json = XmlToJsonConvert(response.data)
                setNumeroPedido(json.numeroPedido)
                setNfe(json.nfe)
                setNomeDestinatario(json.nomeDestinatario)
                setCidade(json.cidade)
                setNumero(json.numero)
                setUf(json.ufSigla)
                setTipoModal(json.modal)
                setPeso(json.peso)
                setVolumes(json.volume)
                setNfeEmitidaCodigo(json.nfeEmitidaCodigo)
                setCaminhoArquivoBase(json.caminhoArquivoBase)
                setPrazoEntrega(formatarData(json.prazoEntrega))
                setValorFrete(formatarDinheiro(json.valorFrete))
            })
        } catch (error) {
            navigate('/')
        }
    }
    const handleObterHistorico = async () => {
        try {
            await api.get(`WsRotinas.asmx/ObterHistoricoPedido`, { params: { pedidoCodigo: pedidoCodigo } }).then((response) => {
                const json = XmlToJsonConvert(response.data);
                setHistorico(json.map(e => ({
                    ...e,
                    data: formatarData(e.data)
                })));
            })
        } catch (error) {
            navigate('/')
        }
    }
    const handleObterProutos = async () => {
        try {
            await api.get(`WsRotinas.asmx/ObterProdutos`, { params: { pedidoCodigo: pedidoCodigo } }).then((response) => {
                const json = XmlToJsonConvert(response.data)
                setProdutos(json.map(e => ({
                    ...e,
                    valorUnitario: formatarDinheiro(e.ValorUnitario)
                })))
            })
        } catch (error) {
            navigate('/')
        }
    }
    useEffect(() => {
        if (pedidoCodigo) {
            handleObterDetalhePedido();
            handleObterHistorico();
            handleObterProutos();
        } else { navigate('/') }
    }, [])

    return (
        <div className='div-conteudo-pedido'>
            <Header titulo={tituloHeader} />
            {loading == true ? <Loading /> :
                <div className='conteudo-dashboard-pedido'>
                    <div className='destinatario'>
                        <div className='h5-container'>
                            <h5>Pedido: {numeroPedido} </h5>
                            <h5>NFe: {nfe}</h5>
                        </div>
                        <h6>DESTINATÁRIO: {nomeDestinatario}</h6>
                        <p>{cidade} <></> {numero}, {uf} <></>
                            MODAL: {tipoModal} | PESO: {peso} | VOLUMES: {volume}</p>
                        <h6>PRAZO DE ENTREGA: {prazoEntrega}</h6>
                        <h6>VALOR DO FRETE: {valorFrete}</h6>
                        <div className='icones'>
                            <FaRegFolderClosed onClick={handleBaixarPdf} />
                        </div>
                    </div>
                    <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <div>
                                        <p>Histórico</p>
                                    </div>
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body"></div>
                                <div className='conteudo-dashboard-historico'>
                                    <div className='historico'>
                                        <h5>Histórico</h5>
                                        {historico.map((e, index) => {
                                            return (
                                                <div className='historico-item' key={index}>
                                                    <div className='icon'>{historico.filter(item => item.descricao.toLowerCase().includes('entrega realizada')).length > 0 ? <IoIosCheckmarkCircle /> : <FaSpinner className="spinner" />}</div>
                                                    <p>{e.data}</p>
                                                    <h6>{e.descricao}</h6>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <div>
                                        <p>Produtos</p>
                                    </div>
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className='conteudo-dashboard-produtos'>
                                        <div className='produtos'>
                                            {produtos.map((e, index) => {
                                                return (
                                                    <>
                                                        <h5>Produtos</h5>
                                                        <h6>{e.Produto}<></></h6>
                                                        <p>COD: {e.PartNumber} | TIPO: {e.TipoProduto} |
                                                            VL UNIT: {e.valorUnitario}
                                                        </p>
                                                        <span>{e.TotalItens}</span>
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            }
        </div>
    );
}